import { type ComponentProps, forwardRef } from "react";

import { cn } from "~/lib/utils";

export const FlexCol = forwardRef<HTMLDivElement, ComponentProps<"div">>(
  function FlexColWithForwardedRef({ className, ...restProps }, ref) {
    return (
      <div
        className={cn("flex flex-col", className)}
        ref={ref}
        {...restProps}
      />
    );
  },
);
